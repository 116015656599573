@font-face {
  font-family: 'Dogica';
  src: url('./fonts/dogica.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'BlackChancery';
  src: url('./fonts/BLKCHCRY.TTF') format('truetype');
  font-weight: normal;
  font-style: normal;
}

.App {
  text-align: center;
  min-height: 100vh;
  background-color: #1a1c2c;
  color: #ffffff;
  width: 100%;
  overflow-x: hidden;
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

.navbar {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem 2rem;
  background: rgba(21, 24, 35, 0.95);
  backdrop-filter: blur(10px);
  width: 100%;
  box-sizing: border-box;
  z-index: 1000;
}

.logo-container {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  margin-right: auto;
}

.site-title {
  font-family: 'BlackChancery', serif;
  font-size: 3rem;
  margin: 0;
  color: white;
  letter-spacing: 2px;
  white-space: nowrap;
  text-align: left;
}

.nav-links {
  flex: 0 0 auto;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: flex-end;
}

.nav-links a {
  color: white;
  text-decoration: none;
  font-size: 0.8rem;
  transition: all 0.3s ease;
  padding: 0.25rem 0.75rem;
  border-radius: 4px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background: rgba(255, 255, 255, 0.05);
}

.nav-links a:hover {
  background: rgba(255, 255, 255, 0.15);
  transform: translateY(-2px);
  border-color: rgba(255, 255, 255, 0.2);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.hero {
  padding: 4rem 2rem;
  background: linear-gradient(135deg, #2a2f4c 0%, #1a1c2c 100%);
  color: white;
}

.hero h1 {
  font-size: 3rem;
  margin-bottom: 1rem;
}

.projects-section, .skills-section {
  padding: 4rem 2rem;
}

.projects-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  padding: 2rem;
}

.project-card {
  background: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  transition: transform 0.2s;
}

.project-card:hover {
  transform: translateY(-5px);
}

.view-project {
  padding: 0.5rem 1rem;
  background-color: #3b82f6;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 1rem;
}

.skills-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  padding: 2rem;
}

.skill-category {
  background: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.skill-category ul {
  list-style: none;
  padding: 0;
}

.skill-category li {
  padding: 0.5rem 0;
  color: #666;
}

@media (max-width: 768px) {
  .nav-links {
    display: none;
  }
  
  .hero h1 {
    font-size: 2rem;
  }
}

/* Dark Mode Styles */
.dark-mode {
  background-color: #1a1a1a;
  color: #ffffff;
}

.dark-mode .navbar,
.dark-mode .project-card,
.dark-mode .skill-category {
  background-color: #2d2d2d;
  color: #ffffff;
}

.dark-mode .nav-links a {
  color: #ffffff;
}

/* Theme Toggle Button */
.theme-toggle {
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  padding: 0.5rem;
  margin-left: 1rem;
}

/* Hero Section Updates */
.hero-buttons {
  margin-top: 2rem;
}

.cta-button {
  display: inline-block;
  padding: 1rem 2rem;
  margin: 0 1rem;
  border-radius: 4px;
  text-decoration: none;
  font-weight: bold;
  transition: transform 0.2s;
}

.cta-button:hover {
  transform: translateY(-2px);
}

.cta-button.secondary {
  background-color: transparent;
  border: 2px solid white;
  color: white;
}

/* Project Cards Update */
.project-card {
  cursor: pointer;
  overflow: hidden;
}

.project-details {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-out;
}

.project-card.expanded .project-details {
  max-height: 200px;
  padding-top: 1rem;
}

.tech-stack {
  color: #666;
  font-size: 0.9rem;
}

/* Skills Progress Bars */
.skill-bar {
  margin-bottom: 1.5rem;
}

.skill-info {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}

.progress-bar {
  height: 8px;
  background-color: #e0e0e0;
  border-radius: 4px;
  overflow: hidden;
}

.progress {
  height: 100%;
  background: linear-gradient(90deg, #6366f1, #3b82f6);
  transition: width 1s ease-in-out;
}

/* Contact Form */
.contact-section {
  padding: 4rem 2rem;
  background-color: #f8f9fa;
}

.contact-form {
  max-width: 600px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.contact-form input,
.contact-form textarea {
  padding: 1rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
}

.contact-form textarea {
  min-height: 150px;
}

.submit-button {
  padding: 1rem 2rem;
  background-color: #3b82f6;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.2s;
}

.submit-button:hover {
  background-color: #2563eb;
}

/* Responsive Design Updates */
@media (max-width: 768px) {
  .nav-links a {
    margin-left: 1rem;
  }
  
  .hero-buttons {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .cta-button {
    margin: 0;
  }
}

/* Enhanced Animations */
@keyframes float {
  0% { transform: translateY(0px); }
  50% { transform: translateY(-20px); }
  100% { transform: translateY(0px); }
}

@keyframes pulse {
  0% { transform: scale(1); }
  50% { transform: scale(1.05); }
  100% { transform: scale(1); }
}

@keyframes slide-in {
  from { transform: translateX(-100px); opacity: 0; }
  to { transform: translateX(0); opacity: 1; }
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% { transform: translateY(0); }
  40% { transform: translateY(-30px); }
  60% { transform: translateY(-15px); }
}

/* Enhanced Navigation */
.navbar {
  background: rgba(255, 255, 255, 0.95);
  backdrop-filter: blur(10px);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
}

.logo-container {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  gap: 1rem;
}

.logo {
  width: 40px;
  height: 40px;
  object-fit: cover;
}

.nav-link {
  position: relative;
  padding: 0.5rem 1rem;
  transition: color 0.3s ease;
}

.nav-link::after {
  content: '';
  position: absolute;
  width: 0;
  height: 2px;
  bottom: 0;
  left: 50%;
  background-color: #3b82f6;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.nav-link:hover::after {
  width: 100%;
  left: 0;
}

/* Enhanced Hero Section */
.hero {
  position: relative;
  overflow: hidden;
  min-height: 80vh;
  background: linear-gradient(135deg, #2a2f4c 0%, #1a1c2c 100%);
}

.hero-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 4rem 2rem;
}

.hero-text {
  flex: 1;
  z-index: 1;
}

.hero-image {
  flex: 1;
  display: flex;
  justify-content: center;
  z-index: 1;
}

.hero-image img {
  max-width: 500px;
  animation: float 6s ease-in-out infinite;
}

.floating {
  animation: float 6s ease-in-out infinite;
}

.pulse {
  animation: pulse 2s ease-in-out infinite;
}

.slide-in {
  animation: slide-in 1s ease-out;
}

.bounce {
  animation: bounce 2s ease-in-out infinite;
}

/* Enhanced Buttons */
.cta-button {
  position: relative;
  padding: 1rem 2rem;
  border-radius: 50px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 600;
  overflow: hidden;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.cta-button.primary {
  background: #fff;
  color: #3b82f6;
}

.cta-button.primary:hover {
  background: #3b82f6;
  color: #fff;
  transform: translateY(-3px);
  box-shadow: 0 10px 20px rgba(59, 130, 246, 0.3);
}

.cta-button.secondary {
  background: transparent;
  border: 2px solid #fff;
  color: #fff;
}

.cta-button.secondary:hover {
  background: #fff;
  color: #3b82f6;
  transform: translateY(-3px);
  box-shadow: 0 10px 20px rgba(255, 255, 255, 0.3);
}

/* Background Elements */
.hero-background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.circle {
  position: absolute;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.1);
}

.circle1 {
  width: 300px;
  height: 300px;
  top: -150px;
  right: -150px;
}

.circle2 {
  width: 200px;
  height: 200px;
  bottom: -100px;
  left: -100px;
}

.circle3 {
  width: 150px;
  height: 150px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* Dark Mode Enhancements */
.dark-mode .navbar {
  background: rgba(45, 45, 45, 0.95);
}

.dark-mode .cta-button.primary {
  background: #3b82f6;
  color: #fff;
}

.dark-mode .cta-button.primary:hover {
  background: #2563eb;
}

/* Responsive Design */
@media (max-width: 768px) {
  .hero-content {
    flex-direction: column;
    text-align: center;
  }

  .hero-image {
    margin-top: 2rem;
  }

  .hero-image img {
    max-width: 300px;
  }
}

.project-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 8px 8px 0 0;
  margin-bottom: 1rem;
  transition: transform 0.3s ease;
}

.project-card:hover .project-image {
  transform: scale(1.05);
}

/* Add these new styles to your existing CSS */

.game-status-section {
  position: relative;
  margin-top: 0;
  padding: 2rem;
  text-align: center;
  background: linear-gradient(135deg, #151823 0%, #1f2437 100%);
  color: white;
  overflow: hidden;
}

.status-container {
  position: relative;
  z-index: 1;
  max-width: 600px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  background: rgba(21, 24, 35, 0.4);
  padding: 2rem;
  border-radius: 15px;
  backdrop-filter: blur(2px);
}

.update-tracker {
  margin-top: 0.5rem;
}

.github-tracker {
  background: rgba(21, 24, 35, 0.85);
  backdrop-filter: blur(10px);
  padding: 0.75rem 1.5rem;
  border-radius: 15px;
  font-size: 0.9rem;
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  border: 1px solid rgba(255, 255, 255, 0.2);
  transition: all 0.3s ease;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.2);
}

.github-tracker:hover {
  background: rgba(21, 24, 35, 0.9);
  transform: translateY(-2px);
}

.github-icon {
  font-size: 1.1rem;
  opacity: 0.9;
}

.status-button {
  padding: 1.5rem 3.5rem;
  font-size: 1.5rem;
  background: linear-gradient(45deg, #FF3366 0%, #FF6B6B 100%);
  color: white;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  margin-bottom: 1rem;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 2px;
  box-shadow: 0 4px 15px rgba(255, 51, 102, 0.4);
  position: relative;
  overflow: hidden;
}

.status-button::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(45deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));
  transform: translateX(-100%);
  transition: transform 0.6s;
}

.status-button:hover {
  transform: translateY(-3px);
  box-shadow: 0 8px 25px rgba(255, 51, 102, 0.5);
}

.status-button:hover::before {
  transform: translateX(100%);
}

@keyframes pulse {
  0% {
    box-shadow: 0 4px 15px rgba(255, 51, 102, 0.4);
  }
  50% {
    box-shadow: 0 4px 25px rgba(255, 51, 102, 0.6);
  }
  100% {
    box-shadow: 0 4px 15px rgba(255, 51, 102, 0.4);
  }
}

.pulse {
  animation: pulse 2s infinite;
}

.okay-button {
  padding: 0.5rem 1rem;
  background-color: #3b82f6;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 1rem;
  font-size: 1rem;
  transition: all 0.3s ease;
}

.okay-button:hover {
  background-color: #2563eb;
  transform: translateY(-2px);
}

.status-response {
  background: rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(10px);
  border-radius: 15px;
  margin-top: 1rem;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.2);
  max-height: 0;
  overflow: hidden;
  opacity: 0;
  transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
  padding: 0;
  pointer-events: none;
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.status-response.show {
  max-height: 300px;
  opacity: 1;
  padding: 2rem;
  pointer-events: auto;
  background: rgba(21, 24, 35, 0.85);
}

.status-response h2,
.status-response p,
.status-response button {
  transform: translateY(20px);
  opacity: 0;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.status-response.show h2,
.status-response.show p,
.status-response.show button {
  transform: translateY(0);
  opacity: 1;
}

.status-response.show h2 { transition-delay: 0.1s; }
.status-response.show p { transition-delay: 0.2s; }
.status-response.show button { transition-delay: 0.3s; }

.tracker-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
}

.tracker-subtitle {
  font-size: 0.8rem;
  opacity: 0.6;
  font-style: italic;
}

.tag {
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.08);
}

.tag:hover {
  background: rgba(255, 255, 255, 0.08);
  transform: translateY(-2px);
}

/* Update navbar styles */
.navbar {
  background: rgba(26, 28, 44, 0.95);
  backdrop-filter: blur(10px);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.2);
  color: #ffffff;
}

/* Add specific light mode overrides */
.App:not(.dark-mode) .navbar {
  background: rgba(255, 255, 255, 0.95);
}

.App:not(.dark-mode) .logo-container h1 {
  color: #1a1c2c;
}

.App:not(.dark-mode) .game-status-section {
  background: linear-gradient(135deg, #f5f5f5 0%, #e5e7eb 100%);
  color: #1a1c2c;
}

.App:not(.dark-mode) .github-tracker {
  background: rgba(0, 0, 0, 0.05);
  color: #1a1c2c;
  border-color: rgba(0, 0, 0, 0.1);
}

.App:not(.dark-mode) .github-tracker:hover {
  background: rgba(0, 0, 0, 0.08);
}

.App:not(.dark-mode) .tracker-subtitle {
  color: #4b5563;
}

.App:not(.dark-mode) .hero {
  background: linear-gradient(135deg, #f0f2f5 0%, #e5e7eb 100%);
  color: #1a1c2c;
}

/* Add this new style for the background image */
/* .game-status-section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url('./assets/base.png');
  background-size: cover;
  background-position: center;
  opacity: 0.95;
  z-index: 0;
} */

/* Add this new section after the game-status-section styles */
.testzone-section {
  padding: 4rem 2rem;
  text-align: center;
  position: relative;
  overflow: visible;
  min-height: 600px;
  background-color: #1a1c2c;
  z-index: 1;
}

.video-container {
  position: relative;
  width: 800px;
  height: 450px;
  margin: 0 auto;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.3);
}

.testzone-video-bg {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  object-fit: cover;
}

@media (max-width: 900px) {
  .video-container {
    width: 90%;
    height: 50vw;
  }
}

.testzone-title {
  position: relative;
  z-index: 1;
  font-size: 4rem;
  color: white;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  margin: 0;
}

/* Add this new style for the section divider */
.game-status-section {
  position: relative;
  /* ... existing styles ... */
}

.game-status-section::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 4px;
  background: linear-gradient(90deg, 
    rgba(255,255,255,0) 0%,
    rgba(255,255,255,0.3) 50%,
    rgba(255,255,255,0) 100%
  );
  box-shadow: 0 1px 2px rgba(0,0,0,0.1);
  z-index: 1;
}

.testzone-section {
  position: relative;
  margin-top: 2px;
  /* ... existing styles ... */
}

.centered-title {
  margin: 0;
  white-space: nowrap;
  font-size: 2.5rem;
  letter-spacing: 2px;
}

/* Adjust nav-links position */
.nav-links {
  margin-left: auto;
}

.steam-wishlist-button {
  margin-top: 2rem;
  padding: 1rem 2.5rem;
  font-size: 1.2rem;
  background: linear-gradient(45deg, #171a21 0%, #2a475e 100%);
  color: white;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.3s ease;
  display: inline-flex;
  align-items: center;
  gap: 0.8rem;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
}

.steam-wishlist-button:hover {
  transform: translateY(-3px);
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.4);
  background: linear-gradient(45deg, #2a475e 0%, #171a21 100%);
}

.steam-wishlist-button.shaking {
  animation: shake 0.4s ease-in-out;
}

.steam-icon {
  font-size: 1.2rem;
  margin-right: 0.2rem;
}

.steam-button-container {
  position: relative;
  display: inline-block;
  z-index: 2001;
}

.steam-message {
  position: absolute;
  left: 50%;
  transform: translateX(-50%) translateY(10px);
  background: rgba(21, 24, 35, 0.95);
  backdrop-filter: blur(10px);
  padding: 0.75rem 1.5rem;
  border-radius: 15px;
  margin-top: 1rem;
  border: 1px solid rgba(255, 255, 255, 0.2);
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.2);
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
  white-space: normal;
  box-sizing: border-box;
}

.steam-message.show {
  opacity: 1;
  visibility: visible;
  transform: translateX(-50%) translateY(0);
}

.steam-message p {
  margin: 0;
  font-size: 0.9rem;
  color: white;
}

/* Add this new keyframes animation */
@keyframes shake {
  0%, 100% { transform: translateX(0); }
  20% { transform: translateX(-5px); }
  40% { transform: translateX(5px); }
  60% { transform: translateX(-3px); }
  80% { transform: translateX(3px); }
}

/* Add this new style for the navbar border */
.navbar::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 4px;
  background: linear-gradient(90deg, 
    rgba(255,255,255,0) 0%,
    rgba(255,255,255,0.3) 50%,
    rgba(255,255,255,0) 100%
  );
  box-shadow: 0 1px 2px rgba(0,0,0,0.1);
  z-index: 1;
}

/* Add a small margin to the game-status-section */
.game-status-section {
  margin-top: 2px;
  /* ... existing styles ... */
}

/* Landing Page Styles */
.landing-page {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  transition: opacity 1s ease-out;
  overflow: hidden;
}

.landing-page.fade-out {
  opacity: 0;
  pointer-events: none;
}

.enter-site-button {
  padding: 1.5rem 4rem;
  font-size: 2rem;
  background: transparent;
  color: white;
  border: 2px solid white;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  text-transform: uppercase;
  letter-spacing: 4px;
  position: relative;
  overflow: visible;
}
.enter-site-button:hover {
  background: rgba(255, 255, 255, 0.1);
  transform: scale(1.05);
}

.main-content {
  opacity: 0;
  transition: opacity 1s ease-in;
  transition-delay: 0.5s;
  padding-top: 80px;
}

.main-content.fade-in {
  opacity: 1;
}

/* Add particle styles */
.particle {
  position: absolute;
  width: 4px;
  height: 4px;
  background: white;
  pointer-events: none;
  border-radius: 50%;
  opacity: 0;
}

@keyframes particle-animation {
  0% {
    transform: translate(0, 0);
    opacity: 1;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    transform: translate(var(--x), var(--y));
    opacity: 0;
  }
}

.particle {
  animation: particle-animation 1.5s ease-out forwards;
}

/* Add these styles */
.visitor-counter {
    background: rgba(21, 24, 35, 0.85);
    backdrop-filter: blur(10px);
    padding: 0.75rem 1.5rem;
    border-radius: 15px;
    font-size: 0.9rem;
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;
    border: 1px solid rgba(255, 255, 255, 0.2);
    transition: all 0.3s ease;
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.2);
    margin-top: 1rem;
    color: white;
}

.visitor-counter:hover {
    transform: translateY(-2px);
}

.visitor-counter span {
  color: white;
}

/* About Section Styles */
.about-section {
  padding: 6rem 2rem;
  background: linear-gradient(135deg, #1a1c2c 0%, #2a2f4c 100%);
  color: white;
}

.about-container {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  gap: 4rem;
  padding: 0 2rem;
}

.about-text {
  flex: 1;
  text-align: left;
}

.about-text h2 {
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
}

.about-text p {
  font-size: 1.1rem;
  line-height: 1.6;
  margin-bottom: 1rem;
  opacity: 0.9;
}

.about-image {
  flex: 1;
  display: flex;
  justify-content: center;
}

.about-image img {
  max-width: 100%;
  height: auto;
  border-radius: 20px;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s ease;
}

.about-image img:hover {
  transform: scale(1.02);
}

.site-footer {
  padding: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(21, 24, 35, 0.95);
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  color: white;
}

/* Update the update-tracker to accommodate the new Steam button */
.update-tracker {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

/* Adjust visitor counter styles for footer placement */
.visitor-counter {
  margin: 0;  /* Remove top margin since it's in the footer now */
}

.footer-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
}

.social-links {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
}

.social-button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1.5rem;
  border-radius: 8px;
  color: white;
  text-decoration: none;
  transition: all 0.3s ease;
  font-size: 1rem;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.social-button.youtube {
  background: rgba(255, 0, 0, 0.2);
}

.social-button.youtube:hover {
  background: rgba(255, 0, 0, 0.3);
  transform: translateY(-2px);
}

.social-button.twitch {
  background: rgba(145, 70, 255, 0.2);
}

.social-button.twitch:hover {
  background: rgba(145, 70, 255, 0.3);
  transform: translateY(-2px);
}

.progress-image {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 3rem;
}

.progress-image img {
  width: 64px;
  height: auto;
  transform: scale(2);
  object-fit: contain;
  image-rendering: pixelated;
}

.upgrade-columns {
  display: flex;
  align-items: center;
  gap: 3rem;
  margin-bottom: 1rem;
}

.upgrade-column {
  width: 32px;
  height: auto;
  transform: scale(2);
  image-rendering: pixelated;
}

.status-content {
  flex: 1;
}

.progress-images {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 2rem;
  padding-top: 3rem;
}

.computer-image{
  width: 64px;
  height: auto;
  transform: scale(2);
  object-fit: contain;
  image-rendering: pixelated;
}

.ore-purchaser-image {
  width: 32px;  /* Smaller base size */
  height: auto;
  transform: scale(2);  /* Same scale factor */
  object-fit: contain;
  image-rendering: pixelated;
  /* Add this to ensure the full image is visible */
  transform-origin: center bottom;
}

.ore-purchaser-container {
  position: relative;
  display: flex;
  justify-content: center;
  margin-bottom: -32px; /* Negative margin to create overlap */
  margin-top: -32px; /* Pull it up into the previous section */
  z-index: 3; /* Make sure it appears above both sections */
}

/* Update these styles */
.progress-text {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.progress-description {
  font-size: 1.0rem;
  line-height: 1.2;
  margin-bottom: 1rem;
  opacity: 1;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.tracker-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: .50rem;
}

.commit-message {
  margin-top: 1.0rem;
  margin-bottom: .50rem;
}

.tracker-subtitle {
  margin-top: .5rem;
}

.tracker-content {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.commit-message-inline {
  font-size: 0.9rem;
  opacity: 0.9;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  padding-top: 0.5rem;
  margin-top: 0.2rem;
}

/* Remove the old commit-message styles since we're not using them anymore */

/* Add these media queries */
@media screen and (max-width: 768px) {
  .tracker-nav {
    display: none;  /* Hide tracker on mobile */
  }

  .nav-links {
    display: none;  /* Hide navigation buttons on mobile */
  }

  .navbar {
    justify-content: center;  /* Center the title */
    padding: 1rem;  /* Reduce padding */
  }

  .navbar::after {
    display: none;  /* Hide the gradient border on mobile */
  }

  .site-title {
    font-size: 1.8rem;  /* Make title slightly smaller on mobile */
  }

  .logo-container {
    margin-right: 0;  /* Remove margin since we're centering */
  }
}

.devlog-feed {
  margin-top: 3rem;
  padding: 2rem;
  background: rgba(21, 24, 35, 0.85);
  border-radius: 15px;
  backdrop-filter: blur(10px);
}

.devlog-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  margin-top: 1.5rem;
}

.devlog-card {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 10px;
  overflow: hidden;
  transition: transform 0.3s ease;
}

.devlog-card:hover {
  transform: translateY(-5px);
}

.devlog-card a {
  color: white;
  text-decoration: none;
}

.devlog-thumbnail {
  width: 100%;
  aspect-ratio: 16/9;
  object-fit: cover;
}

.devlog-card h4 {
  padding: 1rem;
  margin: 0;
  font-size: 1.1rem;
}

.devlog-card p {
  padding: 0 1rem 1rem;
  margin: 0;
  opacity: 0.7;
  font-size: 0.9rem;
}

.steam-notification {
  padding: 1rem;
  text-align: center;
  width: 100%;
  box-sizing: border-box;
}

.steam-notification h3 {
  margin-bottom: 1rem;
  font-size: 1.1rem;
  word-wrap: break-word;
  line-height: 1.4;
}

.steam-notification form {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
}

.steam-notification input {
  padding: 0.5rem;
  border: 2px solid #444;
  border-radius: 4px;
  background: #1a1a1a;
  color: white;
  font-family: inherit;
  width: 100%;
  box-sizing: border-box;
}

.steam-notification button {
  padding: 0.5rem;
  background: #1a9fff;
  border: none;
  border-radius: 4px;
  color: white;
  cursor: pointer;
  font-family: inherit;
  transition: background-color 0.2s;
}

.steam-notification button:hover {
  background: #0084ff;
}

.steam-notification button:disabled {
  background: #666;
  cursor: not-allowed;
}

.success-message {
  color: #4caf50;
  margin-top: 0.5rem;
}

.error-message {
  color: #f44336;
  margin-top: 0.5rem;
}

.steam-message {
  position: absolute;
  left: 50%;
  transform: translateX(-50%) translateY(10px);
  background: rgba(21, 24, 35, 0.95);
  backdrop-filter: blur(10px);
  padding: 0.75rem 1.5rem;
  border-radius: 15px;
  margin-top: 1rem;
  border: 1px solid rgba(255, 255, 255, 0.2);
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.2);
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
  white-space: normal;
  box-sizing: border-box;
}

.steam-button-container {
  position: relative;
  display: inline-block;
  z-index: 2001;
}

.testzone-section {
  position: relative;
  padding: 4rem 2rem;
  text-align: center;
  overflow: visible;
  min-height: 600px;
  background-color: #1a1c2c;
  z-index: 1;
}

.video-container {
  position: relative;
  z-index: 1;
}

/* Update the steam-message positioning */
.steam-message {
  position: absolute;
  background: rgba(21, 24, 35, 0.95);
  backdrop-filter: blur(10px);
  padding: 0.75rem 1.5rem;
  border-radius: 15px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.2);
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
  z-index: 2002;
  width: 300px;
  white-space: normal;
  box-sizing: border-box;
  left: calc(100% + 1rem);
  top: 50%;
  transform: translateY(-50%) translateX(10px);
}

/* Update show class */
.steam-message.show {
  opacity: 1;
  visibility: visible;
  transform: translateY(-50%) translateX(0);
}

/* Remove the specific positioning for top/bottom buttons since we're using the same style for both */
/* Remove these blocks:
.game-status-section .steam-message { ... }
.game-status-section .steam-message.show { ... }
.testzone-section .steam-message { ... }
.testzone-section .steam-message.show { ... }
*/

/* General show class */
.steam-message.show {
  opacity: 1;
  visibility: visible;
}

/* Ensure the progress section doesn't hide the image */
.progress-section {
  position: relative;
  z-index: 1;
}

/* Ensure the game-status-section doesn't hide the image */
.game-status-section {
  position: relative;
  z-index: 1;
}

/* Add specific positioning for the top button's popup */
.game-status-section .steam-message {
  top: 10%;
  transform: translateY(-50%) translateX(10px);
}

.game-status-section .steam-message.show {
  transform: translateY(-50%) translateX(0);
}

